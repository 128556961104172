









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteMediaPopup extends Vue {
  @Prop() popupId!: string;
  @Prop() media!: any;
  @Prop() brandId!: number

  deleteMedia () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('deleteMedia', { brandId: this.brandId, mediaId: this.media.id }).then(() => {
      this.$emit('deleted')
      popupCloseButton.click()
    }, () => popupCloseButton.click())
  }
}
