var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-dialog-centered modal-lg" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _vm._m(0),
              _c("button", {
                ref: "popupCloseButton",
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              })
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c(
                "form",
                {
                  attrs: { "data-vv-scope": _vm.formType },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addMedia($event)
                    }
                  }
                },
                [
                  _c("div", [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              "text-align": "center"
                            }
                          },
                          [_vm._v("Basic Details")]
                        ),
                        _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.title,
                                expression: "media.title"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'"
                              }
                            ],
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has(
                                _vm.getfieldName("title")
                              )
                            },
                            attrs: {
                              type: "text",
                              placeholder: "Media Title",
                              name: "title",
                              maxlength: "23"
                            },
                            domProps: { value: _vm.media.title },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.media,
                                  "title",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.videoUrl,
                                expression: "media.videoUrl"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  url: { require_protocol: true }
                                },
                                expression:
                                  "{\n                      required: true,\n                      url: { require_protocol: true }\n                    }"
                              }
                            ],
                            class: {
                              input: true,
                              "is-danger": _vm.errors.has(
                                _vm.getfieldName("videoUrl")
                              )
                            },
                            attrs: {
                              type: "text",
                              placeholder: "Video URL",
                              name: "videoUrl",
                              maxlength: "1024"
                            },
                            domProps: { value: _vm.media.videoUrl },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.media,
                                  "videoUrl",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.media.surveyId,
                                  expression: "media.surveyId"
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'"
                                }
                              ],
                              class: {
                                input: true,
                                "is-danger": _vm.errors.has(
                                  _vm.getfieldName("surveyId")
                                )
                              },
                              attrs: { name: "surveyId" },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.media,
                                    "surveyId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    disabled: "",
                                    selected: "",
                                    value: ""
                                  }
                                },
                                [_vm._v("Select Insights - Part 1")]
                              ),
                              _vm._l(_vm.nonGeoSurveys, function(survey) {
                                return _c(
                                  "option",
                                  {
                                    key: survey.id,
                                    domProps: { value: survey.id }
                                  },
                                  [_vm._v(_vm._s(survey.title))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.description,
                                expression: "media.description"
                              }
                            ],
                            staticClass: "input textarea",
                            attrs: {
                              maxlength: "200",
                              placeholder:
                                "Video Description- 200 Characters Max",
                              rows: "2"
                            },
                            domProps: { value: _vm.media.description },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.media,
                                  "description",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-6" }, [
                        _c(
                          "h3",
                          {
                            staticStyle: {
                              "margin-top": "20px",
                              "text-align": "center"
                            }
                          },
                          [_vm._v("Action Links")]
                        ),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.haveAppLinks,
                                expression: "media.haveAppLinks"
                              }
                            ],
                            attrs: { type: "checkbox", id: "app-links" },
                            domProps: {
                              checked: Array.isArray(_vm.media.haveAppLinks)
                                ? _vm._i(_vm.media.haveAppLinks, null) > -1
                                : _vm.media.haveAppLinks
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.media.haveAppLinks,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.media,
                                          "haveAppLinks",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.media,
                                          "haveAppLinks",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.media, "haveAppLinks", $$c)
                                  }
                                },
                                function($event) {
                                  return _vm.toggleLinkFields(
                                    $event,
                                    "haveAppLinks"
                                  )
                                }
                              ]
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: { "margin-top": "6px" },
                              attrs: { for: "app-links" }
                            },
                            [_vm._v("App Links")]
                          )
                        ]),
                        _c("div", { staticClass: "col-sm-6" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.haveWebLinks,
                                expression: "media.haveWebLinks"
                              }
                            ],
                            attrs: { type: "checkbox", id: "web-link" },
                            domProps: {
                              checked: Array.isArray(_vm.media.haveWebLinks)
                                ? _vm._i(_vm.media.haveWebLinks, null) > -1
                                : _vm.media.haveWebLinks
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.media.haveWebLinks,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.media,
                                          "haveWebLinks",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.media,
                                          "haveWebLinks",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.media, "haveWebLinks", $$c)
                                  }
                                },
                                function($event) {
                                  return _vm.toggleLinkFields(
                                    $event,
                                    "haveWebLinks"
                                  )
                                }
                              ]
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: { "margin-top": "6px" },
                              attrs: { for: "web-link" }
                            },
                            [_vm._v("Website Link")]
                          )
                        ]),
                        _vm.media.haveAppLinks
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass: "col-sm-12",
                                  staticStyle: { "margin-top": "10px" }
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.media.appName,
                                        expression: "media.appName"
                                      }
                                    ],
                                    class: {
                                      input: _vm.media.haveAppLinks,
                                      "is-danger": _vm.errors.has(
                                        _vm.getfieldName("appName")
                                      )
                                    },
                                    attrs: {
                                      type: "text",
                                      placeholder: "App Name",
                                      name: "appName",
                                      maxlength: "1024"
                                    },
                                    domProps: { value: _vm.media.appName },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.media,
                                          "appName",
                                          $event.target.value
                                        )
                                      }
                                    }
                                  })
                                ]
                              ),
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.media.iosAppLink,
                                      expression: "media.iosAppLink"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        url: { require_protocol: true }
                                      },
                                      expression:
                                        "{\n                        url: { require_protocol: true }\n                      }"
                                    }
                                  ],
                                  class: {
                                    input: _vm.media.haveAppLinks,
                                    "is-danger": _vm.errors.has(
                                      _vm.getfieldName("iosAppLink")
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "iOS App Link",
                                    name: "iosAppLink",
                                    maxlength: "1024"
                                  },
                                  domProps: { value: _vm.media.iosAppLink },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.media,
                                        "iosAppLink",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.media.androidAppLink,
                                      expression: "media.androidAppLink"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        url: { require_protocol: true }
                                      },
                                      expression:
                                        "{\n                        url: { require_protocol: true }\n                      }"
                                    }
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": _vm.errors.has(
                                      _vm.getfieldName("androidAppLink")
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Android App Link",
                                    name: "androidAppLink",
                                    maxlength: "1024"
                                  },
                                  domProps: { value: _vm.media.androidAppLink },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.media,
                                        "androidAppLink",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e(),
                        _vm.media.haveWebLinks
                          ? _c("div", [
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.media.appName,
                                      expression: "media.appName"
                                    }
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": _vm.errors.has(
                                      _vm.getfieldName("AppName")
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Website Name/Title",
                                    name: "appName"
                                  },
                                  domProps: { value: _vm.media.appName },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.media,
                                        "appName",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "col-sm-12" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.media.websiteUrl,
                                      expression: "media.websiteUrl"
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        url: { require_protocol: true }
                                      },
                                      expression:
                                        "{\n                        url: { require_protocol: true }\n                      }"
                                    }
                                  ],
                                  class: {
                                    input: true,
                                    "is-danger": _vm.errors.has(
                                      _vm.getfieldName("websiteUrl")
                                    )
                                  },
                                  attrs: {
                                    type: "text",
                                    placeholder: "Website Url Link",
                                    name: "websiteUrl",
                                    maxlength: "1024"
                                  },
                                  domProps: { value: _vm.media.websiteUrl },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.media,
                                        "websiteUrl",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "col-sm-12" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.media.loyaltyNumberRequired,
                                expression: "media.loyaltyNumberRequired"
                              }
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "loyalty-number-required"
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.media.loyaltyNumberRequired
                              )
                                ? _vm._i(
                                    _vm.media.loyaltyNumberRequired,
                                    null
                                  ) > -1
                                : _vm.media.loyaltyNumberRequired
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.media.loyaltyNumberRequired,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.media,
                                        "loyaltyNumberRequired",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.media,
                                        "loyaltyNumberRequired",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.media,
                                    "loyaltyNumberRequired",
                                    $$c
                                  )
                                }
                              }
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: { "margin-top": "6px" },
                              attrs: { for: "loyalty-number-required" }
                            },
                            [_vm._v("Required Account/Loyalty Number?")]
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "col-sm-12" }, [
                        _c("h3", { staticClass: "text-center" }, [
                          _vm._v("Loyalty Images")
                        ]),
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("div", { staticClass: "col-xs-12 croppa-tip" }),
                            _vm._l(_vm.croppers, function(cropper, i) {
                              return _c(
                                "div",
                                {
                                  key: "product-image-" + i,
                                  staticClass:
                                    "col-xs-12 col-sm-6 col-md-6 text-center"
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "upload-remove-container" },
                                    [
                                      _c("croppa", {
                                        attrs: {
                                          placeholder: "Select Image",
                                          "initial-size": "contain",
                                          "placeholder-font-size": 25,
                                          "show-remove-button": false,
                                          "initial-image": _vm.initialImages[i]
                                        },
                                        model: {
                                          value: _vm.croppers[i],
                                          callback: function($$v) {
                                            _vm.$set(_vm.croppers, i, $$v)
                                          },
                                          expression: "croppers[i]"
                                        }
                                      }),
                                      _vm.croppers[i] &&
                                      _vm.croppers[i].hasImage()
                                        ? _c("button", {
                                            staticClass: "upload-remove",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function($event) {
                                                return _vm.croppers[i].remove()
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.imageNames[i],
                                            expression: "imageNames[i]"
                                          }
                                        ],
                                        staticClass: "mb30",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Title",
                                          maxlength: "31"
                                        },
                                        domProps: { value: _vm.imageNames[i] },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.imageNames,
                                              i,
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    ]),
                    _c(
                      "ul",
                      _vm._l(_vm.errors.all(), function(error) {
                        return _c("li", { key: error }, [_vm._v(_vm._s(error))])
                      }),
                      0
                    )
                  ]),
                  _c("input", {
                    staticClass: "btn-lg-green m0",
                    attrs: { type: "submit", disabled: _vm.busy },
                    domProps: {
                      value: _vm.editMediaFlag ? "UPDATE MEDIA" : "ADD MEDIA"
                    }
                  })
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Media")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }