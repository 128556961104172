<template>
  <div
    :id="popupId"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h2><img src="/img/Alerts/plusbig_icon.png" /> Media</h2>
          <button
            ref="popupCloseButton"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form v-on:submit.prevent="addMedia" :data-vv-scope="formType">
            <div>
              <div class="row">
                <div class="col-sm-6">
                  <h3 style="margin-top:20px; text-align:center;">Basic Details</h3>
                  <div class="col-xs-12 col-sm-12">
                    <input
                      type="text"
                      placeholder="Media Title"
                      name="title"
                      v-model="media.title"
                      v-validate="'required'"
                      maxlength="23"
                      :class="{
                        input: true,
                        'is-danger': errors.has(getfieldName('title'))
                      }"
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12">
                    <input
                      type="text"
                      placeholder="Video URL"
                      name="videoUrl"
                      v-model="media.videoUrl"
                      v-validate="{
                        required: true,
                        url: { require_protocol: true }
                      }"
                      maxlength="1024"
                      :class="{
                        input: true,
                        'is-danger': errors.has(getfieldName('videoUrl'))
                      }"
                    />
                  </div>
                  <div class="col-xs-12 col-sm-12">
                    <select
                      v-model="media.surveyId"
                      name="surveyId"
                      v-validate="'required'"
                      :class="{
                        input: true,
                        'is-danger': errors.has(getfieldName('surveyId'))
                      }"
                    >
                      <option disabled selected value>Select Insights - Part 1</option>
                      <option
                        v-for="survey in nonGeoSurveys"
                        :key="survey.id"
                        :value="survey.id"
                        >{{ survey.title }}</option
                      >
                    </select>
                  </div>
                  <div class="col-xs-12 col-sm-12">
                    <textarea
                      maxlength="200"
                      v-model="media.description"
                      placeholder="Video Description- 200 Characters Max"
                      class="input textarea"
                      rows="2"
                    ></textarea>
                  </div>
                </div>
                <div class="col-sm-6">
                  <h3 style="margin-top:20px; text-align:center;">Action Links</h3>
                  <div class="col-sm-6"><input type="checkbox" id="app-links" @change="toggleLinkFields($event, 'haveAppLinks')" v-model="media.haveAppLinks"/><label for="app-links"  style="margin-top:6px;">App Links</label></div>
                  <div class="col-sm-6"><input type="checkbox" id="web-link" @change="toggleLinkFields($event, 'haveWebLinks')" v-model="media.haveWebLinks"/><label for="web-link"  style="margin-top:6px;">Website Link</label></div>
                  <div v-if="media.haveAppLinks">
                    <div class="col-sm-12" style="margin-top:10px;">
                      <input
                        type="text"
                        placeholder="App Name"
                        name="appName"
                        v-model="media.appName"
                        maxlength="1024"
                        :class="{
                          input: media.haveAppLinks,
                        'is-danger': errors.has(getfieldName('appName'))
                        }"
                      />
                    </div>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        placeholder="iOS App Link"
                        name="iosAppLink"
                        v-model="media.iosAppLink"
                        v-validate="{
                          url: { require_protocol: true }
                        }"
                        maxlength="1024"
                        :class="{
                          input: media.haveAppLinks,
                        'is-danger': errors.has(getfieldName('iosAppLink'))
                        }"
                      />
                    </div>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        placeholder="Android App Link"
                        name="androidAppLink"
                        v-model="media.androidAppLink"
                        v-validate="{
                          url: { require_protocol: true }
                        }"
                        maxlength="1024"
                        :class="{
                          input: true,
                        'is-danger': errors.has(getfieldName('androidAppLink'))
                        }"
                      />
                    </div>
                  </div>
                  <div v-if="media.haveWebLinks">
                    <div class="col-sm-12">
                      <input type="text"
                        placeholder="Website Name/Title"
                        v-model="media.appName"
                        name="appName"
                        :class="{input: true, 'is-danger': errors.has(getfieldName('AppName'))}"
                        />
                    </div>
                    <div class="col-sm-12">
                      <input
                        type="text"
                        placeholder="Website Url Link"
                        name="websiteUrl"
                        v-model="media.websiteUrl"
                        v-validate="{
                          url: { require_protocol: true }
                        }"
                        maxlength="1024"
                        :class="{
                          input: true,
                          'is-danger': errors.has(getfieldName('websiteUrl'))}"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12"><input type="checkbox" id="loyalty-number-required"  v-model="media.loyaltyNumberRequired"/><label for="loyalty-number-required"  style="margin-top:6px;">Required Account/Loyalty Number?</label></div>

                </div>
                <div class="col-sm-12">
                  <h3 class="text-center">Loyalty Images</h3>
                  <div class="row">
                    <div class="col-xs-12 croppa-tip"></div>
                    <div
                      class="col-xs-12 col-sm-6 col-md-6 text-center"
                      v-for="(cropper, i) in croppers"
                      :key="'product-image-' + i"
                    >
                      <div class="upload-remove-container">
                        <croppa
                          v-model="croppers[i]"
                          placeholder="Select Image"
                          initial-size="contain"
                          :placeholder-font-size="25"
                          :show-remove-button="false"
                          :initial-image="initialImages[i]"
                        ></croppa>

                        <button
                          v-if="croppers[i] && croppers[i].hasImage()"
                          class="upload-remove"
                          type="button"
                          @click="croppers[i].remove()"
                        ></button>
                        <input
                          type="text"
                          v-model="imageNames[i]"
                          placeholder="Title"
                          class="mb30"
                          maxlength="31"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                <li v-for="error in errors.all()" :key="error">{{ error }}</li>
              </ul>
            </div>
            <input
              type="submit"
              class="btn-lg-green m0"
              :value="editMediaFlag ? 'UPDATE MEDIA' : 'ADD MEDIA'"
              :disabled="busy"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'MediaPopup',
  props: ['popupId', 'mediaDetails', 'editMediaFlag', 'brandId'],
  data () {
    return {
      formType: 'add-media',
      busy: false,
      nonGeoSurveys: [],
      media: {
        title: '',
        videoUrl: '',
        androidAppLink: '',
        description: '',
        iosAppLink: '',
        websiteName: '',
        brandId: this.brandId,
        surveyId: '',
        appName: '',
        images: [],
        haveAppLinks: 1,
        haveWebLinks: 0,
        websiteUrl: '',
        loyaltyNumberRequired: 0
      },
      croppers: new Array(4),
      mediaImages: new Array(4),
      imageNames: [],
      limitPerPage: 100,
      offset: 0,
      mediaSections: [],
      initialImages: new Array(4)
    }
  },
  methods: {
    getfieldName (field) {
      console.log()
      return this.formType + '.' + field
    },

    toggleLinkFields (ev, type) {
      if (type === 'haveWebLinks') {
        if (ev.target.checked) {
          this.media.haveAppLinks = 0
        } else {
          this.media.haveAppLinks = 1
        }
      }
      if (type === 'haveAppLinks') {
        if (ev.target.checked) {
          this.media.haveWebLinks = 0
        } else {
          this.media.haveWebLinks = 1
        }
      }
    },

    generateImage (index) {
      let url = this.croppers[index].generateDataUrl()
      if (!url) {
        return
      }
      this.mediaImages[index] = url
    },
    addMedia () {
      for (var i = 0; i < 4; ++i) this.generateImage(i)
      this.$validator.validateAll(this.formType).then(result => {
        if (result && !this.busy) {
          this.busy = true
          this.mediaImages.forEach((x, index) => {
            if (x && x.length > 0) this.media.images.push({ 'image': x, 'description': this.imageNames[index] })
          })
          if (this.editMediaFlag) {
            this.$store.dispatch('updateMedia', this.media).then(
              (response) => {
                this.uploadMediaImages(this.media.id, this.media.images)
                this.$emit('updated')
                this.success()
              },
              response => {
                this.$emit('failed')
                for (var key in response.body) {
                  this.$notify({ type: 'error', text: response.body[key][0] })
                  let field = this.$validator.fields.find({
                    name: key,
                    scope: this.formType
                  })
                  if (field) {
                    this.$validator.errors.add({
                      id: field.id,
                      field: key,
                      msg: response.body[key][0],
                      scope: this.formType
                    })
                  }
                }
                this.busy = false
              }
            )
          } else {
            this.$store.dispatch('saveMedia', this.media).then(
              (response) => {
                this.uploadMediaImages(response.body.id, this.media.images)
                this.resetForm()
                this.success()
              },
              response => {
                this.$emit('failed')
                for (var key in response.body) {
                  this.$notify({ type: 'error', text: response.body[key][0] })
                  let field = this.$validator.fields.find({
                    name: key,
                    scope: this.formType
                  })

                  if (field) {
                    this.$validator.errors.add({
                      id: field.id,
                      field: key,
                      msg: response.body[key][0],
                      scope: this.formType
                    })
                  }
                }
                this.busy = false
              }
            )
          }
        } else {
          console.log(this.errors)
          this.$emit('failed')
        }
      })
    },

    uploadMediaImages (mediaId, images) {
      this.$store
        .dispatch('uploadMediaImages', {
          brandId: this.brandId,
          mediaId: mediaId,
          images: images
        })
        .then(
          response => {
            this.nonGeoSurveys.push(...response.response.body.surveys)
            this.$notify({
              type: 'success',
              text: 'Media has been saved successfully'
            })
            this.busy = false
          },
          response => {}
        )
    },

    resetForm () {
      Object.assign(this.$data, this.$options.data.apply(this))
      this.$nextTick().then(() => {
        this.getNonGeoSurveys()
        this.errors.clear(this.formType)
      })
    },

    getNonGeoSurveys () {
      this.$store
        .dispatch('getSurveysList', {
          limit: this.limitPerPage,
          offset: this.offset,
          brand_id: this.brandId,
          type: 'MEDIA'
        })
        .then(
          response => {
            this.nonGeoSurveys.push(...response.response.body.surveys)
          },
          response => {}
        )
    },

    success () {
      this.$refs.popupCloseButton.click()
      this.$emit('added')
      this.busy = false
    }
  },
  mounted () {
    this.formType = this.editMediaFlag === true ? 'edit-media' : 'add-media'
    this.getNonGeoSurveys()
  },
  watch: {
    mediaDetails () {
      this.media.title = this.mediaDetails.title
      this.media.surveyId = this.mediaDetails.surveyId
      this.media.appName = this.mediaDetails.appName
      this.media.description = this.mediaDetails.description
      this.media.id = this.mediaDetails.id
      this.media.iosAppLink = this.mediaDetails.iosAppLink
      this.media.androidAppLink = this.mediaDetails.androidAppLink
      this.media.videoUrl = this.mediaDetails.videoUrl
      this.media.websiteUrl = this.mediaDetails.websiteUrl
      this.media.haveAppLinks = this.mediaDetails.haveAppLinks
      this.media.haveWebLinks = this.mediaDetails.haveWebLinks
      this.media.loyaltyNumberRequired = this.mediaDetails.loyaltyNumberRequired
      var i
      this.media.images = []
      this.initialImages = []
      for (i = 0; i < 4; i++) {
        this.croppers[i].refresh()
        this.imageNames[i] = ''
        if (this.editMediaFlag && (typeof this.mediaDetails.mediaImages !== 'undefined')) {
          if (typeof this.mediaDetails.mediaImages[i] !== 'undefined') {
            let image = new Image()
            image.setAttribute('crossorigin', 'anonymous')
            image.src = this.mediaDetails.mediaImages[i].image
            this.initialImage = image
            this.initialImages[i] = image
            this.imageNames[i] = this.mediaDetails.mediaImages[i].description
          }
        }
      }
    }
  }
}
</script>
<style scoped>
.row >>> .croppa-container {
  position: relative;
  padding-top: 100%;
  width: 100%;
}

.row >>> .croppa-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}

.btn-negative {
  font-size: 15px;
  font-weight: 400;
  margin-top: 0;
}

select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url('/img/Create Pickl/selectmediaDropdown_icon.png');
  background-color: white;
}

option {
  color: black;
  background-color: white;
}
.textarea {
  width: 100%;
}
</style>
