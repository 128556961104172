var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-bg green-border-box outer-box" }, [
    _c("div", { staticClass: "inner-box" }, [
      _c("span", { staticClass: "title", attrs: { title: _vm.media.title } }, [
        _vm._v(_vm._s(_vm.media.title))
      ]),
      _c("br"),
      _c(
        "span",
        { staticClass: "description", attrs: { title: _vm.media.description } },
        [_vm._v(_vm._s(_vm.media.description.slice(0, 50).concat("...")))]
      ),
      _c("br"),
      _c("div", { staticClass: "text-left float-left" }, [
        _c(
          "button",
          {
            staticClass: "btn-negative",
            attrs: {
              "data-target": "#delete-media-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("delete", _vm.media)
              }
            }
          },
          [_vm._v("DELETE")]
        )
      ]),
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn-positive",
            attrs: {
              "data-target": "#edit-media-popup",
              "data-toggle": "modal",
              type: "button"
            },
            on: {
              click: function($event) {
                return _vm.$emit("editMedia", _vm.media)
              }
            }
          },
          [_vm._v("EDIT")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }