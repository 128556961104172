





















































import MediaItem from '@/components/common/MediaItem.vue'
import DeleteMediaPopup from '@/components/common/DeleteMediaPopup.vue'
import MediaPopup from '@/components/common/MediaPopup.vue'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    MediaItem,
    DeleteMediaPopup,
    MediaPopup
  }
})
export default class Media extends Vue {
  public media: any[] = []
  public query = ''
  public limit = 100
  public offset = 0
  public brandId = 0
  public actionMedia: any = null
  public totalCount = 0
  public busy: boolean = false
  public product: {
    name: string
    section: string
    description: string
    images: any[]
  } = {
    name: '',
    section: '',
    description: '',
    images: []
  }
  public mediaDetails: object = {}

  getMedia (clear = true) {
    this.busy = true
    this.$store
      .dispatch('getMediaList', {
        limit: this.limit,
        offset: this.offset,
        brand_id: this.brandId
      })
      .then(
        response => {
          this.media.push(...response.response.body.media)
          this.busy = false
        },
        response => {
          console.log(response)
          this.busy = false
        }
      )
  }

  loadMore () {
    this.$data.offset += this.$data.limit
    this.getMedia(false)
  }

  @Watch('query')
  onSearch () {
    Vue.common.delay(() => {
      this.offset = 0
      this.getMedia()
    }, 500)()
  }

  created () {
    this.brandId = this.$store.state.userData.brandId
    this.getMedia()
  }

  refresh () {
    console.log('here we are')
    this.media = []
    this.offset = 0
    this.getMedia()
  }

  editMediaDetails (media: object) {
    this.mediaDetails = media
  }

  get hasLoadMore (): boolean {
    return this.media.length < this.totalCount
  }
}
