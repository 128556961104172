var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      !_vm.busy && _vm.media.length > 0
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.media, function(m) {
              return _c(
                "div",
                { key: m.id, staticClass: "col-xs-12 col-sm-6 item-list" },
                [
                  _c("MediaItem", {
                    attrs: { media: m },
                    on: {
                      delete: function($event) {
                        _vm.actionMedia = m
                      },
                      editMedia: _vm.editMediaDetails
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "text-center" }, [
        _vm.hasLoadMore
          ? _c(
              "button",
              {
                staticClass: "btn-positive",
                attrs: { disabled: _vm.busy },
                on: { click: _vm.loadMore }
              },
              [_vm._v("\n      Load more\n    ")]
            )
          : _vm._e()
      ]),
      !_vm.busy && (!_vm.media || _vm.media.length == 0)
        ? _c("h3", { staticClass: "text-center mt20" }, [
            _vm._v("\n    No Media Content found!\n  ")
          ])
        : _vm._e(),
      _c("MediaPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "add-media-popup",
          editMediaFlag: false
        },
        on: { added: _vm.refresh }
      }),
      _c("MediaPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "edit-media-popup",
          editMediaFlag: true,
          mediaDetails: _vm.mediaDetails
        },
        on: { updated: _vm.refresh }
      }),
      _c("DeleteMediaPopup", {
        attrs: {
          brandId: _vm.brandId,
          popupId: "delete-media-popup",
          media: _vm.actionMedia
        },
        on: { deleted: _vm.refresh }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }